import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import './SignIn.scss';
import { login } from '../../wanwanh_services/requests';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


const SignIn = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const email = values.email;
    const password = values.password;

    try {
      const response = await login(email, password);
      const user = response.data.user;
      const token = response.data.token;

      if (user && token) {
        Cookies.set('sessionId', user.id, { expires: 14 });
        Cookies.set('token', token, { expires: 14 })
        navigate(`/home`);
      } else {
        message.error('Wrong Email or Password');  
      }
    } catch (e) {
      message.error("User is unauthorized to use this service");
    }
  };

  useEffect(() => {
    const sessionId = Cookies.get('sessionId');
    if (sessionId) {
      navigate(`/home`);
    }
  }, [navigate]);

  return (
    <div className="SignIn">
      <img className="logo" src='/logo.png' alt="logo" />
      <Form
        name="login"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Email" name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Password" name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember Me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" style={{ background: '#e66a04' }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignIn;
