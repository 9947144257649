import { sendTextMsg, sendDocumentMsg, sendImageWithCaptionMsg, getMessages, getFile, sendRecordMsg } from "../../../wanwanh_services/requests";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import ConversationContext from "./ConversationContext";
import { useFetch } from "../../../utils/hooks";
import { SendOutlined, LinkOutlined, PlusOutlined, FileImageOutlined, ArrowLeftOutlined, AudioOutlined } from '@ant-design/icons';
import { Button, message, Upload, Card, Popover } from 'antd';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import ImageCard from "./ImageCard/ImageCard";
import AudioCard from "./AudioCard/AudioCard";
import { getStandardDate } from "../../../utils/date_formatter";
import "./Conversation.scss"

const Conversation = () => {
  const { conversationID, openedConversation, setOpenedConversation, openedContactCard, setOpenedContactCard, messages, setMessages, page, setPage } = useContext(ConversationContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isSendAudioCLicked, setIsSendAudioClicked] = useState(false);
  const recorderControls = useAudioRecorder()
  const messageContainerRef = useRef(null);

  const hidePopover = () => {
    setPopoverOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setPopoverOpen(newOpen);
  };

  const uploadProps = (kind) => {
    return {
      name: "file",
      accept: kind === "file" ? '*' : "image/*",
      action: `${process.env.REACT_APP_BASE_URL}/upload_file`,
      maxCount: 1,
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} ${kind} uploaded successfully`);
          kind === "file" ? setSelectedFile(info.file.name) : setSelectedImage(info.file.name);
          hidePopover();
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} ${kind} upload failed.`);
        }
      },
      onRemove() {
        setSelectedFile(null);
      }
    };
  }

  const { data, refetch } = useFetch(useCallback(async () => {
    const lastMessage = messages[messages.length - 1]?.id
    const response = await getMessages(conversationID, page, lastMessage)
    const newMessages = response.data?.messages || []
    const newIncoming = response.data?.new_incoming || []
    setMessages([...newMessages, ...messages, ...newIncoming])
    return response
  }, [page, conversationID]));
  
  const totalPages = data?.total_pages

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      sendTextMsg(event.target.value, messages[0].chat_name);
      event.target.value = "";
    }
  };

  const isUserMessage = (messageData) => {
    return messageData.author === null || messageData.author === "You" || messageData.author === "Nowlun";
  };

  const handleSend = () => {
    if (selectedFile) {
      sendDocumentMsg(selectedFile, messages[0].chat_name);
      setSelectedFile(null);
    }
    if (selectedImage) {
      sendImageWithCaptionMsg(selectedImage, "", messages[0].chat_name)
      setSelectedImage(null);
    }
    hidePopover();
  };

  const handleDownload = async (file_path) => {
    try {
      const response = await getFile(file_path);
      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = file_path;
      link.click();

      URL.revokeObjectURL(url); // Clean up after download
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    setOpenedConversation(false);
  }

  const formatDate = (date) => {
    return getStandardDate(date);
  };

  const sendAudio = () => {
    sendRecordMsg(recorderControls.recordingBlob, messages[0].chat_name);
  };

  const inRecordingProcess = () => {
    return (recorderControls.isPaused || recorderControls.isRecording);
  }

  const handleSendAudioClick = () => {
    recorderControls.stopRecording();
    setIsSendAudioClicked(true);
  }

  function handleScroll(e) {
    const scrollTop = e.currentTarget.scrollTop
    if (!scrollTop) setPage(page < totalPages ? page + 1 : totalPages)
  }

  //FIXME: introduce delay to allow the component to render then execute the scrolling
  // useEffect(() => {
  //   const delay = 200;
  //   const timer = setTimeout(() => {

  //     // Scroll to the bottom of the container
  //     if (messageContainerRef.current && page === 1 && !isReady) {
  //       messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  //     }
  //   }, delay);

  //   // Clear the timer if the component unmounts or the dependency array changes
  //   return () => clearTimeout(timer);
  // }, [isReady]);


  useEffect(() => {
    if (messages !== null && recorderControls.recordingBlob !== undefined && recorderControls.isRecording === false && isSendAudioCLicked) {
      sendAudio();
      setIsSendAudioClicked(false);
    }
  }, [recorderControls.recordingBlob]);

  useEffect(() => {
    const intervalId = setInterval(refetch, 5 * 1000);
    return () => clearInterval(intervalId)
  }, [conversationID, messages]);

  return (
    <div className="Conversation" data-is-opened={openedConversation && !openedContactCard}>
      <div className="profile-header">
        <ArrowLeftOutlined className="backward-arrow" onClick={handleGoBack} />
        <img className="profile-image" src="/profile/user.png" onClick={() => { setOpenedContactCard(true) }} />
        {messages.length === 0 ? "" : (messages[0].username || messages[0].chat_name)}
      </div>
      <div className="message-container" ref={messageContainerRef} onScroll={handleScroll}>
        {messages.map((messageData) => (
          <div className="message-sub-container" key={messageData.id} data-is-yours={isUserMessage(messageData)}>
            <div className="message-content" data-is-yours={isUserMessage(messageData)}>
              {!isUserMessage(messageData) && <div className="author"> {messageData.username || messageData.author} </div>}
              {messageData.kind === "text" && messageData.body.text}
              {messageData.kind === "document" && <Card className="document-card" hoverable cover={<img alt="document" src="/download-screen.png" />} onClick={() => { handleDownload(messageData.body.file_path) }}></Card>}
              {messageData.kind === "image" && <ImageCard messageData={messageData} />}
              {(messageData.kind === "image" || messageData.kind === "document") && messageData.body.caption}
              {messageData.kind === "record" && <AudioCard messageData={messageData} />}
              <div className="time"> {formatDate(messageData.date)} </div>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-box">
        {inRecordingProcess() && <AudioRecorder className="audio-recorder"
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          recorderControls={recorderControls}
          showVisualizer={true}
        />}
        {inRecordingProcess() && <Button className="audio-send-btn" icon={<SendOutlined className="chat-bar-icon" />} onClick={handleSendAudioClick} />}
        {!inRecordingProcess() && <Button className="audio-mic-btn" icon={<AudioOutlined className="chat-bar-icon" />} onClick={recorderControls.startRecording} />}
        {!inRecordingProcess() && <div className="chat-bar">
          <Popover
            content={<div className="popover-menu">
              <Upload {...uploadProps("image")}>
                <div className="popover-element" data-is-last={false}>
                  <FileImageOutlined className="popup-icon" />
                  <span className="popover-text">Image</span>
                </div>
              </Upload>
              <Upload {...uploadProps("file")} >
                <div className="popover-element" data-is-last={true}>
                  <LinkOutlined className="popup-icon" />
                  <span className="popover-text">Document</span>
                </div>
              </Upload>
            </div>
            }

            title="Attachments"
            trigger="click"
            open={popoverOpen}
            onOpenChange={handleOpenChange}>
            <Button className="chat-bar-btn" icon={<PlusOutlined className="chat-bar-icon" />}></Button>
          </Popover>
          <textarea className="message-input" placeholder="Type a message" onKeyUp={handleKeyUp} />
          {(selectedFile !== null || selectedImage !== null) && <Button className="chat-bar-btn" icon={<SendOutlined className="chat-bar-icon" />} onClick={handleSend} />}
        </div>}
      </div>
    </div>
  );
};

export default Conversation;

