import React, { useState } from 'react';

const UserContext = React.createContext();

const UserProvider = ({ children, primaryUser}) => {
  const [impersonatedUser, setImpersonatedUser] = useState(primaryUser);

  return (
    <UserContext.Provider value={{ impersonatedUser, setImpersonatedUser, primaryUser}}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
export default UserContext;
