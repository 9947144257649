import React, { useCallback, useContext, useState } from 'react';
import { useFetch } from '../../../utils/hooks';
import { getFilteredUserChats } from '../../../wanwanh_services/requests';
import UserContext from '../UserContext';

const ConversationContext = React.createContext();

const ConversationProvider = ({ children }) => {
  const { impersonatedUser } = useContext(UserContext);

  const [openedConversation, setOpenedConversation] = useState(false);
  const [openedContactCard, setOpenedContactCard] = useState(false);
  const [customersFilter, setCustomersFilter] = useState(false);
  const [unreadFilter, setUnreadFilter] = useState(false);
  const [conversationID, setConversationID] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1)

  function switchConversation(newConversationId) {
    if (newConversationId === conversationID) return;
    setConversationID(newConversationId)
    setOpenedConversation(true)
    setMessages([])
    setPage(1)
  }

  const { data, refetch } = useFetch(useCallback(
    () => getFilteredUserChats(impersonatedUser.id, currentPage, searchInput, unreadFilter, customersFilter),
    [currentPage, searchInput, impersonatedUser, unreadFilter, customersFilter]
  ))

  const chats = data?.chats || [];
  const totalChats = data?.total_chats || 0;


  return (
    <ConversationContext.Provider value={{
      conversationID,
      setConversationID,
      openedConversation,
      setOpenedConversation,
      openedContactCard,
      setOpenedContactCard,
      messages,
      setMessages,
      page,
      setPage,
      switchConversation,
      setCurrentPage,
      setCustomersFilter,
      chats,
      totalChats,
      refetchContactList: refetch,
      setSearchInput,
      setUnreadFilter
    }}>
      {children}
    </ConversationContext.Provider>
  );
};

export { ConversationProvider };
export default ConversationContext;
