import React, { useEffect, useContext } from 'react';
import "./ContactList.scss";
import { Input, Pagination, Tabs } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import ConversationContext from "../Conversation/ConversationContext";
import UserContext from "../UserContext";
import debounce from "lodash/debounce";
import { EnvironmentOutlined } from '@ant-design/icons';
import { format, isToday, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const Contact = ({ chatData }) => {
    const { switchConversation } = useContext(ConversationContext);

    function handleClick() {
        switchConversation(chatData.id)
    };

    const formatDate = (dateString) => {
        const parsedDate = parse(dateString, "yyyy-MM-dd HH:mm:ss xx", new Date());
        const zonedDate = utcToZonedTime(parsedDate, 'UTC');
        if (!isToday(zonedDate)) {
            return format(zonedDate, 'yyyy/MM/dd');
        } else {
            return format(zonedDate, 'HH:mm');
        }
    };

    return (
        <div className="ContactItem" onClick={handleClick}>
            <div className="contact-info">
                <span className="contact-name">{chatData.username || chatData.chat_name}</span>
                <span className="contact-company">
                    {<EnvironmentOutlined />}
                    {" " + (chatData.company || "Unknown").slice(0, 37)}
                </span>
                <span className="message-text">{chatData.last_msg?.kind === "text" && (chatData.last_msg.body.text.slice(0, 20) + " ...")}</span>
            </div>
            {(chatData.unread_count !== 0) && <span className="unread-count">{chatData.unread_count}</span>}
            <span className="message-date">{formatDate(chatData.last_msg_date)}</span>
        </div>
    );
};

const ContactList = () => {
    const { openedConversation, setCurrentPage, setCustomersFilter, totalChats, chats, refetchContactList, setSearchInput, setUnreadFilter, searchInput, unreadFilter} = useContext(ConversationContext);
    const { impersonatedUser } = useContext(UserContext);

    const handleFilter = (e) => {
        setSearchInput(e.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    }

    const handleTabsClick = (key) => {
        setUnreadFilter(key === 2)
        setCustomersFilter(key === 3)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!searchInput && !unreadFilter) {
                refetchContactList()
            }
        }, 5 * 1000);
        return () => clearInterval(intervalId)
    }, [chats, searchInput, unreadFilter]);

    const debouncedHandleFilter = debounce(handleFilter, 500)

    return (
        <div className="ContactList" data-is-closed={openedConversation}>
            <div className="profile-info">
                <img className="profile-image" src="/nowlun.png" />
                <div className="profile-name">{impersonatedUser.name}</div>
            </div>
            <Tabs
                defaultActiveKey="1"
                type="card"
                size="small"
                items={[
                    { key: 1, label: "All" },
                    { key: 2, label: "Unread" },
                    { key: 3, label: "Customers" }
                ]}
                onTabClick={handleTabsClick}
            />
            <Input
                className="search-input"
                addonBefore={<SearchOutlined />}
                placeholder="Search for contact ..."
                onChange={debouncedHandleFilter}
            />

            <div className="contacts-column">
                {
                    chats.map(chatData => <Contact key={chatData.id} chatData={chatData} />)
                }
            </div>
            <Pagination className="pagination" size="small" showSizeChanger={false} onChange={handlePagination} total={totalChats} />
        </div>
    );
};

export default ContactList;

