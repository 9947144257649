import React, { useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import "./Home.scss";

import { UserProvider } from './UserContext';
import { ConversationProvider } from './Conversation/ConversationContext';
import { useFetch } from '../../utils/hooks';
import { getUser } from '../../wanwanh_services/requests';
import ContactList from './ContactList/ContactList';
import Conversation from './Conversation/Conversation';
import UserFolders from './UserFolders/UserFolders';
import ContactCard from './ContactCard/ContactCard';
import { LoadingOutlined } from '@ant-design/icons';
import { subscribe } from '../../serviceWorkerRegistration';


const Home = () => {
  const userID = Cookies.get('sessionId');
  const { data, loading } = useFetch(useCallback(() => getUser(parseInt(userID)), [userID]))
  const user = data?.user || {};

  useEffect(() => {
    user.id && subscribe();
  }, [user.id]);


  if (loading) {
    return (
      <div className="Home">
        <LoadingOutlined className="loading-icon" />
      </div>
    );
  }


  return (
    <div className="Home">
      <UserProvider primaryUser={user}>
        {user.wanwanh_admin && <UserFolders />}
        <ConversationProvider>
          <ContactList />
          <Conversation />
          <ContactCard />
        </ConversationProvider>
      </UserProvider>
    </div>
  );
}

export default Home;
