import React from 'react';
import { Card, Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import ConversationContext from "../Conversation/ConversationContext";
import UserContext from "../UserContext";
import { clearChatUnreadCount, getContactDetails } from '../../../wanwanh_services/requests';
import { getStandardDate } from '../../../utils/date_formatter';
import UserAssigner from './UserAssigner/UserAssigner';
import "./ContactCard.scss";
import { message } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import SalesAssigner from './SalesAssigner/SalesAssigner';


const ContactCard = () => {
  const { conversationID, openedContactCard, setOpenedContactCard, refetchContactList } = useContext(ConversationContext);
  const { primaryUser } = useContext(UserContext);
  const [contactDetails, setContactDetails] = useState({});

  function handleReadClicked() {
    clearChatUnreadCount(conversationID)
    refetchContactList()
  }

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        let response = await getContactDetails(conversationID);
        setContactDetails(response.data.contact_details);
      } catch (error) {
        message.error('User not found');
        setContactDetails({})
      }
    };

    conversationID && fetchContactDetails();
  }, [conversationID]);



  if (conversationID) {
    return (
      <div className='ContactCard' data-is-opened={openedContactCard}>
        <UpOutlined className='close-icon' onClick={() => setOpenedContactCard(false)} />
        {primaryUser.wanwanh_admin &&
          <Card
            className='wanwanh-card'
            title="Assigned Users"
            hoverable={true}
            type='inner'
          >
            <UserAssigner />
          </Card>}
        <br />
        <Card
          className='wanwanh-card'
          title="Personal Info"
          hoverable={true}
          type='inner'
        >
          <div className='card-wrapper'>
            <div className='card-row'>
              <span className='card-label'>Phone: </span> <span className='value'>{contactDetails.phone}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Email: </span> <span className='value'>{contactDetails.email}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Created At: </span> <span className='value'>{contactDetails.created_at && getStandardDate(contactDetails.created_at)}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Last Sign-In: </span> <span className='value'>{contactDetails.last_sign_in_at && getStandardDate(contactDetails.last_sign_in_at)}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Sign-In Count: </span> <span className='value'>{contactDetails.sign_in_count}</span>
            </div>
          </div>
        </Card>
        <br />
        <Card
          className='wanwanh-card'
          title="Company Info"
          hoverable={true}
          type='inner'
        >
          <div className='card-wrapper'>
            <div className='card-row'>
              <span className='card-label'>Company: </span> <span className='value'>{contactDetails.company_name}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Industry: </span> <span className='value'>{contactDetails.company_industry}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Country: </span> <span className='value'>{contactDetails.company_country}</span>
            </div>
            {
              primaryUser.wanwanh_admin && (
                <div className='card-row'>
                  <span className='card-label'>Sales Representative: </span>{contactDetails.id && <SalesAssigner user={contactDetails.id} representative={contactDetails.sales_representative} />}
                </div>
              )
            }
          </div>
        </Card>
        <br />
        <Card
          className='wanwanh-card'
          title="Shipments Info"
          hoverable={true}
          type='inner'
        >
          <div className='carf-wrapper'>
            <div className='card-row'>
              <span className='card-label'>Nowlun Shipments Count: </span> <span className='value'>{contactDetails.shipments_count}</span>
            </div>
            <div className='card-row'>
              <span className='card-label'>Tracking Shipments Count: </span> <span className='value'>{contactDetails.tracking_shipments_count}</span>
            </div>
          </div>

        </Card>
        <br />
        <Button onClick={handleReadClicked}>
          Mark as Read
        </Button>
      </div>
    );
  }
};

export default ContactCard;
