import React, { useState, useEffect} from 'react';
import { Button, Drawer, Select, Space, Divider } from 'antd';
import { PlusOutlined, ArrowsAltOutlined, MinusOutlined, EditOutlined, EditFilled} from '@ant-design/icons';
import { addUserToChat, getChats ,removeUserFromChat, getUserAssignedChats} from '../../../../wanwanh_services/requests';
import './PermissionsDrawer.scss';

const PermissionsDrawer = ({users}) => {
    const [open, setOpen] = useState(false);
    const [chats, setChats] = useState([]);
    const [userChats, setUserChats] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedChat, setSelectedChat] = useState(null);
    const [removedUser, setRemovedUser] = useState(null);
    const [removedChat, setRemovedChat] = useState(null);
    const showDrawer = () => {
        setOpen(true);
    };

    function onClose () {
        setSelectedUser(null);
        setSelectedChat(null);
        setRemovedUser(null);
        setRemovedChat(null);
        setOpen(false);
    };

    const handleUserAssignChange = (value) => {
        setSelectedUser(value);
    };

    const handleChatAssignChange = (value) => {
        setSelectedChat(value);
    };

    const handleUserRemoveChange = (value) => {
        setRemovedUser(value);
        const fetchUserChats = async() => {
            let response = await getUserAssignedChats(value);
            setUserChats((response.data.chats?.length && response.data.chats) || []);
        };
        fetchUserChats();
    };

    const handleChatRemoveChange = (value) => {
        setRemovedChat(value);
    };


    const handleSubmit = () => {
        if (selectedUser && selectedChat) {
            addUserToChat(selectedUser, selectedChat);
            onClose();
        }
    }
    const handleRemove = () => {
        if (removedUser && removedChat) {
            removeUserFromChat(removedUser, removedChat);
            onClose();
        }
    }
    useEffect(() => {
        const fetchChats = async () => {
            let response = await getChats();
            response.data.chats?.length && setChats(response.data.chats);

        };

        fetchChats();
    }, []);

    return (
        <div className='PermissionsDrawer'>
                <Button type="primary" className="permissions-button" onClick={showDrawer} >
                    <EditOutlined /> Permissions
                </Button>
                <Drawer title="Edit Permissions" width={520} closable={true} onClose={onClose} open={open}>
                    <Divider orientation="left" >Add Permissions</Divider>
                    <div className="permissions-director">
                        <div className="selection-section">
                            <Space wrap>
                                <Select
                                placeholder="Select User"
                                defaultValue={null}
                                value={selectedUser}
                                style={{
                                    width: 130,
                                }}
                                onChange={handleUserAssignChange}
                                options={
                                    users.map((user) => (
                                        {value: user.id, label: user.name}
                                    ))
                                }
                                />
                            </Space>
                            <Space wrap>
                                <ArrowsAltOutlined className="arrow-icon" rotate={45} />
                            </Space>
                            <Space wrap>
                                <Select
                                placeholder="Select Chat"
                                defaultValue={null}
                                value={selectedChat}
                                style={{
                                    width: 130,
                                }}
                                onChange={handleChatAssignChange}
                                options={
                                    chats.map((chat) => (
                                        {value: chat.id, label: (chat.username || chat.chat_name)}
                                    ))
                                }
                                />
                            </Space>
                        </div>
                        <Button className="permissions-button" type="primary" onClick={handleSubmit} >
                            <PlusOutlined /> Submit
                        </Button>
                    </div>
                    <Divider orientation="left" >Remove Permissions</Divider>
                    <div className="permissions-remover">
                        <div className="selection-section">
                            <Space wrap>
                                <Select
                                placeholder="Select User"
                                defaultValue={null}
                                value={removedUser}
                                style={{
                                    width: 130,
                                }}
                                onChange={handleUserRemoveChange}
                                options={
                                    users.map((user) => (
                                        {value: user.id, label: user.name}
                                    ))
                                }
                                />
                            </Space>
                            <Space wrap>
                                <ArrowsAltOutlined className="arrow-icon" rotate={45} />
                            </Space>
                            <Space wrap>
                                <Select
                                placeholder="Select Chat"
                                defaultValue={null}
                                value={removedChat}
                                style={{
                                    width: 130,
                                }}
                                onChange={handleChatRemoveChange}
                                options={
                                    userChats.map((chat) => (
                                        {value: chat.id, label: (chat.username || chat.chat_name)}
                                    ))
                                }
                                />
                            </Space>
                        </div>
                        <Button className="permissions-button" type="primary" onClick={handleRemove} >
                            <MinusOutlined /> Remove
                        </Button>
                    </div>
                </Drawer>
        </div>
    );
};

export default PermissionsDrawer;
