import React from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home/Home';
import SignIn from './SignIn/SignIn';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/home/*"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route path="/" element={<SignIn />} />
      </Routes>
    </Router>
  );
};

const ProtectedRoute = ({ children }) => {
  const userID = Cookies.get('sessionId');
  if (!userID) return <Navigate to='/' replace />
  return children
}

export default AppRouter;
