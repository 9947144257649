import React, { useEffect, useState, useContext } from 'react';
import { Select } from 'antd';
import { addSalesRepToCompany, getSalesRep } from '../../../../wanwanh_services/requests';
import ConversationContext from '../../Conversation/ConversationContext';
import './SalesAssigner.scss';


const SalesAssigner = ({ user, representative }) => {
  const [salesReps, setSalesReps] = useState([]);
  const { conversationID } = useContext(ConversationContext);

  const handleSelect = (sales_rep_id) => {
    addSalesRepToCompany(user, sales_rep_id);
  };

  useEffect(() => {
    const fetchSalesReps = async () => {
      let response = await getSalesRep();
      setSalesReps(response.data.sales_reps);
    };

    fetchSalesReps();
  }, [conversationID]);

  return (
    <div className='SalesAssigner'>
      {
        representative
          ? <span className='value'>{representative}</span>
          : <Select
              className='select'
              placeholder="Sales Reps"
              options={salesReps.map((sales_rep) => ({ value: sales_rep.id, label: sales_rep.name }))}
              onSelect={handleSelect}
            />
      }
    </div>
  )
}

export default SalesAssigner;
