import { getFile } from "../../../../wanwanh_services/requests";
import ReactAudioPlayer from 'react-audio-player';
import { useState } from "react";
import "./AudioCard.scss";


const AudioCard = ({messageData}) => {
    const [audioUrl, setAudioUrl] = useState(null);

    useState(async () => {
        const response = await getFile(messageData.body.record_path);
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);

        return () => URL.revokeObjectURL(url);
        }, []);
    return (
        <ReactAudioPlayer className="audio-player" src={audioUrl} controls />
        )
};

export default AudioCard;
