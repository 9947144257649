import { getFile } from "../../../../wanwanh_services/requests";
import { Card } from 'antd';
import { useState } from "react";

const ImageCard = ({messageData}) => {
    const [imageUrl, setImageUrl] = useState(null);

    useState(async () => {
        const response = await getFile(messageData.body.img_path);
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        setImageUrl(url);

        return () => URL.revokeObjectURL(url);
        }, []);
    return (
        <Card hoverable cover={<img alt="image" src={imageUrl} />}></Card>
        )
};

export default ImageCard;
