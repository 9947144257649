import React, { useEffect, useState, useContext } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
import { getUsers } from '../../../wanwanh_services/requests';
import PermissionsDrawer from './PermissionsDrawer/PermissionsDrawer';
import UserContext from '../UserContext';
import './UserFolders.scss';

const { Sider } = Layout;

const UserFolders = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [users, setUsers] = useState([]);
    const { setImpersonatedUser } = useContext(UserContext);

    const handleClick = (user) => {
        setImpersonatedUser(user);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            let response = await getUsers();
            setUsers(response.data.users);
        };

        fetchUsers();
    }, []);

    return (
            <Layout hasSider>
                <Sider className="users-sider" trigger={null} collapsible collapsed={collapsed}>
                    <Button className="trigger" type="text" icon={collapsed ? <MenuUnfoldOutlined className="menu-folding" /> : <MenuFoldOutlined className="menu-folding" />} onClick={() => setCollapsed(!collapsed)} />
                    <Menu
                        theme="dark"
                        mode="inline"
                        items={ users.map((user) => ({
                                key: user.id,
                                icon: <UserOutlined />,
                                label: user.name,
                                onClick: () => {handleClick(user)},
                            }))
                        }
                    />
                    {!collapsed && <PermissionsDrawer users={users}/>}
                </Sider>
            </Layout>
    );
};

export default UserFolders;
