import { useEffect, useState } from "react";

export const useFetch = (service) => {
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");
  const [shouldRefetch, setShouldRefetch] = useState(false);

  useEffect(() => {
    service()
      .then((res) => res.data)
      .then((data) => {
        seterror(data.error)
        setdata(data)
        setloading(false)
      })
  }, [service, shouldRefetch]);

  const refetch = () => {setShouldRefetch(!shouldRefetch)}

  return { data, loading, error, refetch };
};
