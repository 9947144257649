
import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function getStandardDate(stringDate){
  const parsedDate = parse(stringDate, 'yyyy-MM-dd HH:mm:ss xx', new Date());
  const zonedDate = utcToZonedTime(parsedDate, 'UTC');
  return format(zonedDate, "yyyy/MM/dd HH:mm")

}

export function getDateObject(formattedDateString){
    // Split the formatted string to extract date and time components
  const [datePart, timePart] = formattedDateString.split(', ');

  // Split the date part into day, month, and year
  const [day, month, year] = datePart.split('/').map(Number);

  // Split the time part into hour and minute
  const [hour, minute] = timePart.split(':').map(Number);

  // Create a new Date object using the extracted components
  return new Date(year, month - 1, day, hour, minute);
}
