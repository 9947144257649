import React, { useEffect, useState, useContext } from 'react';
import { Select, Tag } from 'antd';
import { getUsers, addUserToChat, removeUserFromChat, getChatAssignedUsers } from '../../../../wanwanh_services/requests';
import ConversationContext from '../../Conversation/ConversationContext';
import './UserAssigner.scss';

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className='assigner-tag'
      color={'orange'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};
const UserAssigner = () => {
  const [users, setUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const { conversationID } = useContext(ConversationContext);

  const handleSelect = (value, option) => {
    addUserToChat(value, conversationID);
    setAssignedUsers([...assignedUsers, { value: value, label: option.label }]);
  };

  const handeleDeselect = (value) => {
    removeUserFromChat(value, conversationID);
    setAssignedUsers(assignedUsers.filter((user) => user.value !== value));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      let response = await getUsers();
      setUsers(response.data.users);
    };
    const fetchAssignedUsers = async () => {
      let response = await getChatAssignedUsers(conversationID);
      setAssignedUsers(response.data.users.map((user) => ({ value: user.id, label: user.name })));
    };

    fetchAssignedUsers();
    fetchUsers();
  }, [conversationID]);

  return (
    <div className='UserAssigner'>
      <Select
        className='user-assigner-select'
        mode="multiple"
        tagRender={tagRender}
        placeholder="Assign Users"
        value={assignedUsers}
        options={users.map((user) => ({ value: user.id, label: user.name }))}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        onSelect={handleSelect}
        onDeselect={handeleDeselect}
      />
    </div>

  );
}
export default UserAssigner;
