import Cookies from 'js-cookie';
import axios from './http';


export function sendTextMsg(text, chatName) {
  const token = Cookies.get("token");
  axios.post("/send_text_msg",{ msg: text, name: chatName, token: token})
}

export function sendDocumentMsg(filePath, chatName) {
  const token = Cookies.get("token");
  axios.post("/send_document_msg",{ file_path: filePath, name: chatName, token: token})
}

export function sendImageWithCaptionMsg(imgPath, caption, chatName) {
  const token = Cookies.get("token");
  axios.post("/send_image_with_caption_msg",{ img_path: imgPath, caption: caption, name: chatName, token: token})
}

export function sendRecordMsg(blob, chatName) {
  const token = Cookies.get("token");
  axios.post("/send_record_msg",{ blob: blob, name: chatName, token: token}, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set the content type for FormData
    },})
}

export function getMessages(conversationID, page, lastMessage) {
  const token = Cookies.get("token");
  return ((conversationID !== 0) ? axios.get("/get_chat_msgs", {params: {chat_id: conversationID, token, page, last_message: lastMessage}}) : Promise.resolve({data: {messages: []}}))
}

export function getFile(file_path) {
  const token = Cookies.get("token");
  return axios.post('/download_file', { file_path: file_path, token: token}, {responseType: "arraybuffer"})
}

export function clearChatUnreadCount(chatID) {
  const token = Cookies.get("token");
  return axios.post("/clear_chat_unread_count", {chat_id: chatID, token: token})
}

export function getUsers() {
  const token = Cookies.get("token");
  return axios.get("/get_users", {params: {token: token}})
}

export function getSalesRep() {
  const token = Cookies.get("token");
  return axios.get("/get_sales_rep", {params: {token: token}})
}

export function addUserToChat(userID, chatID) {
  const token = Cookies.get("token");
  return axios.post("/add_user_to_chat", {user_id: userID, chat_id: chatID, token: token})
}

export function addSalesRepToCompany(userID, salesRepID) {
  const token = Cookies.get("token");
  return axios.post("/add_sales_rep_to_company", {user_id: userID, sales_rep_id: salesRepID, token: token})
}

export function removeUserFromChat(userID, chatID) {
  const token = Cookies.get("token");
  return axios.post("/remove_user_from_chat", {user_id: userID, chat_id: chatID, token: token})
}

export function getFilteredUserChats(userID, currentPage, searchInput, unreadFilter, customersFilter){
  const token = Cookies.get("token");
  return axios.post("/get_filtered_user_chats", {user_id: userID, page: currentPage, search_input: searchInput, unread_filter: unreadFilter, customers_filter: customersFilter, token: token})
}

export function getUserAssignedChats(userID) {
  const token = Cookies.get("token");
  return axios.post("/get_user_assigned_chats", {user_id: userID, token: token})
}

export function getChatAssignedUsers(chatID) {
  const token = Cookies.get("token");
  return axios.post("/get_chat_assigned_users", {chat_id: chatID, token: token})
}

export function getChats() {
  const token = Cookies.get("token");
  return axios.get("/get_chats", {params: {token: token}})
}

export function login(email, password) {
  return axios.post("/login", {email: email, password: password})
}

export function getUser(userID) {
  const token = Cookies.get("token");
  return axios.post("/get_user", {user_id: userID, token: token})
}

export function getContactDetails(chatID) {
  const token = Cookies.get("token");
  return axios.post("/get_contact_details", {chat_id: chatID, token: token})
}

export function subscribeToNotifications(subscription) {
  const token = Cookies.get("token");
  return axios.post("/subscribe_to_notifications", {subscription: subscription,  token: token})
}
